<div class="px-3 pb-4">
  <h2 class="text-left mt-3 mb-0">Block Calendar Setup Parameters</h2>
  <hr class="mt-2" />
  <form
    class="mat-typography mt-4 px-3"
    [formGroup]="blockScheduleForm"
    (ngSubmit)="submitForm()"
  >
    <mat-form-field class="col-11">
      <mat-label>Select a Template Schedule</mat-label>
      <mat-select
        formControlName="template_schedule"
        name="template"
        (selectionChange)="handleChangeTemplate()"
      >
        @for (template of templates; track template) {
        <mat-option [value]="template.value">{{
          template.viewValue
        }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class="input-group mb-3">
      <label class="input-group-text col-4 p-3" for="cycle_start_date"
        >Start Cycle on<span class="text-danger">*</span></label
      >
      <input
        id="cycle_start_date"
        type="date"
        class="form-control col-7"
        placeholder="Start Date"
        aria-label="Start Date"
        aria-describedby="cycle_start_date"
        formControlName="cycle_start_date"
      />
      <span class="input-group-text col-1 border border-0 bg-transparent"
        ><mat-icon
          aria-hidden="false"
          aria-label="Help Icon"
          fontIcon="help"
          matTooltip="The date of when the first day of classes start."
        ></mat-icon
      ></span>
    </div>
    <div class="input-group mb-3">
      <label class="input-group-text col-4 p-3" for="day_start_time"
        >School Start Time<span class="text-danger">*</span></label
      >
      <input
        id="day_start_time"
        type="time"
        class="form-control col-7"
        placeholder="Start Time"
        aria-label="Start Time"
        aria-describedby="day_start_time"
        formControlName="day_start_time"
      />
      <span class="input-group-text col-1 border border-0 bg-transparent"
        ><mat-icon
          aria-hidden="false"
          aria-label="Help Icon"
          fontIcon="help"
          matTooltip="The time the school day starts."
        ></mat-icon
      ></span>
    </div>
    <div class="input-group mb-3">
      <label class="input-group-text col-4 p-3" for="periods_per_day"
        >Periods Per Day<span class="text-danger">*</span></label
      >
      <input
        id="periods_per_day"
        type="number"
        class="form-control col-7"
        placeholder="Number of Periods Per Day"
        aria-label="Number of Periods Per Day"
        aria-describedby="periods_per_day"
        formControlName="periods_per_day"
      />
      <span class="input-group-text col-1 border border-0 bg-transparent"
        ><mat-icon
          aria-hidden="false"
          aria-label="Help Icon"
          fontIcon="help"
          matTooltip="The number of periods including breaks and flex times in a day."
        ></mat-icon
      ></span>
    </div>
    <div class="input-group mb-3">
      <label class="input-group-text col-4 p-3" for="default_period_length"
        >Period or Phase Duration<span class="text-danger">*</span></label
      >
      <input
        id="default_period_length"
        type="number"
        class="form-control col-7"
        placeholder="Minutes Per Period"
        aria-label="Minutes Per Period"
        aria-describedby="default_period_length"
        formControlName="default_period_length"
      />
      <span class="input-group-text col-1 border border-0 bg-transparent"
        ><mat-icon
          aria-hidden="false"
          aria-label="Help Icon"
          fontIcon="help"
          matTooltip="The typical length of time per period. These can be adjusted after setup."
        ></mat-icon
      ></span>
    </div>
    <div class="input-group mb-3">
      <label class="input-group-text col-4 p-3" id="days_per_cycle"
        >Days Per Cycle<span class="text-danger">*</span></label
      >
      <input
        id="days_per_cycle"
        type="number"
        class="form-control col-7"
        placeholder="Number of Days Per Cycle"
        aria-label="Number of Days Per Cycle"
        aria-describedby="days_per_cycle"
        formControlName="days_per_cycle"
      />
      <span class="input-group-text col-1 border border-0 bg-transparent"
        ><mat-icon
          aria-hidden="false"
          aria-label="Help Icon"
          fontIcon="help"
          matTooltip="The number of days before the schedule repeats. A typicaly Monday to Friday cycle is five days."
        ></mat-icon
      ></span>
    </div>
    <div class="col-12">
      <mat-checkbox class="me-2" formControlName="adjust_around_holidays"
        >Adjust around holidays</mat-checkbox
      >
    </div>
    <div class="col-12">
      <mat-checkbox class="me-2" formControlName="adjust_around_pro_d"
        >Adjust around professional development days</mat-checkbox
      >
    </div>
    <div class="col-12">
      <mat-checkbox class="me-2" formControlName="weekends_off"
        >No classes on weekends</mat-checkbox
      >
    </div>
    <div class="mt-3 d-flex justify-content-center">
      <button
        mat-flat-button
        color="basic"
        mat-dialog-close
        class="btn-cancel mx-3"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        cdkFocusInitial
        color="warn"
        class="btn-save mx-3"
        type="submit"
        [disabled]="!blockScheduleForm.valid"
      >
        Save
      </button>
    </div>
  </form>
</div>
